#marquee-wrapper {
  position: relative;
  overflow: hidden;
}

#marquee-wrapper::before {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  background: linear-gradient(to right, rgb(247,250,252) 0%, rgba(155, 148, 148, 0) 50%);
}
#marquee-wrapper::after {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  background: linear-gradient(to left, rgb(247,250,252) 0%, rgba(155, 148, 148, 0) 50%);
}

  



