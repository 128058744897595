

.newsDescription h1 {
  display: block;
  font-size: 2em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.newsDescription h2 {
  display: block;
  font-size: 1.5em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.newsDescription h3 {
  display: block;
  font-size: 1.17em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.newsDescription h4 {
  display: block;
  font-size: 1em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.newsDescription h5 {
  display: block;
  font-size: .83em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.newsDescription h6 {
  display: block;
  font-size: .67em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.newsDescription p  {
    margin-top: 7px;
    margin-bottom: 7px;
}
